
import { defineComponent, computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import Top from '@/components/admin/top.vue'
import Left from '@/components/admin/left.vue'

export default defineComponent({
  setup () {
    const store = useStore()
    const isCollapse = computed(() => store.getters.getIsCollapse)

    const screenWidth = ref(0)
    onMounted(() => {
      window.addEventListener('resize', function () {
        // console.log(document.body.offsetWidth)
        screenWidth.value = document.body.offsetWidth
      })
    })

    watch(screenWidth, (n, o) => {
      if (n <= 1200) {
        // console.log(n, '屏幕宽度小于1200了')
        store.commit('switchCollapse', true)
      } else {
        // console.log(n, '屏幕宽度没有小于1200')
        store.commit('switchCollapse', false)
      }
    })

    return { isCollapse }
  },

  components: { Top, Left }
})
