
import { defineComponent, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { user } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const router = useRouter()
    const toView = (url: string) => {
      router.push({ path: url })
    }

    const loginOut = () => {
      // router.go(0)
      window.localStorage.removeItem('token')
      router.replace({ path: '/login' })
    }
    // 获取 DOM 元素
    const domTopRef: any = ref(null)
    // domTopRef.value.style.marginLeft = '200px'
    const store = useStore()
    const crumbList = computed(() => store.getters.getCrumbList)
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const switchCollapse = () => {
      store.commit('switchCollapse', !isCollapse.value)
    }

    return {
      user,
      toView,
      loginOut,
      domTopRef,
      crumbList,
      isCollapse,
      switchCollapse
    }
  }
})
