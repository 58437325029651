
import { defineComponent, computed } from 'vue'
import { website } from '@/utils/webSideInfo'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const store = useStore()
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const currentMenu = computed(() => store.getters.getCurrentMenu)

    const router = useRouter()
    const toView = (url: string) => {
      router.push({ path: url })
    }

    return {
      website,
      isCollapse,
      currentMenu,
      toView
    }
  }
})

