export const website = {
  logo: require('@/assets/image/logo.png'),

  menuList: [{
    index: '1',
    name: '关于夷正',
    url: '/about'
  }, {
    index: '8',
    name: '联系我们',
    url: '/contact'
  }, {
    index: '9',
    name: '登录|注册',
    url: '/login'
  }],

  adminMenuList: [{
    index: 'Admin',
    name: '首页',
    icon: 'HomeFilled',
    url: '/admin'
  }, {
    index: 'example',
    name: '组件',
    icon: 'ElementPlus',
    url: '/admin/example'
  }, {
    index: 'Setting',
    name: '设置',
    icon: 'Tools',
    url: '/admin/setting',
    children: [{
      index: 'Personal',
      name: '个人中心',
      icon: 'User',
      url: '/admin/setting/personal'
    }, {
      index: 'Security',
      name: '安全设置',
      icon: 'Lock',
      url: '/admin/setting/Security'
    }]
  }]
}

export const rights = {
  Copyright: 'Copyright © 2016-2024 cclwl All Rights Reserved 夷正科技',
  record: '沪ICP备20000150号',
  recordUrl: 'https://beian.miit.gov.cn',
  gongan: '沪公网安备 31011202012168号',
  gonganUrl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012168'
}

export const companyInfo = {
  name: '上海夷正信息科技有限公司',
  email: 'admin@yizhengtoc.com',
  mobile: '（021）8728473',
  address: '上海市嘉定工业区叶城路912号'
}

export const aboutInfo = {
  name: '上海夷正信息科技有限公司',
  paragraph1: '成立于2016年12月16日，坐落于中国的魔都--上海。',
  paragraph2: '公司主要经营软件类产品开发：1、企业官网的搭建，拥有上千模板供客户选择；2、日常办公OA系统，全力打造无纸化办公环境；3、智能进销存系统，实现资产库存统一化管理；4、定制化推广开发公众号；5、专业量身打造各行业小程序。',
  paragraph3: '随着公司不断的发展壮大，我们有好的产品和专业的销售和技术团队，始终为客户提供好的产品和技术支持、健全的售后服务！'
}

export const user = {
  name: '逝水无痕',
  account: 'admin',
  password: '123456',
  photoUrl: 'https://img0.baidu.com/it/u=2924490029,2419431244&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200',
  token: 'abcdefghijklmnopqrstuvwxyz'
}
