import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d10a506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "active-text-color": "#409EFF",
    "background-color": "#304156",
    "text-color": "#fff",
    class: "el-menu-vertical",
    "default-active": _ctx.currentMenu,
    collapse: _ctx.isCollapse
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.website.adminMenuList, (v) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: v.name
        }, [
          (v.children && v.children.length > 0 )
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(_component_el_sub_menu, {
                  index: v.index,
                  key: v.index
                }, {
                  title: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(v.icon)))
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", null, _toDisplayString(v.name), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.children, (c) => {
                      return (_openBlock(), _createBlock(_component_el_menu_item, {
                        index: c.index,
                        key: c.index,
                        onClick: ($event: any) => (_ctx.toView(c.url))
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(c.name), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(c.icon)))
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["index", "onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["index"]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(), _createBlock(_component_el_menu_item, {
                  index: v.index,
                  key: v.index,
                  onClick: ($event: any) => (_ctx.toView(v.url))
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(v.name), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(v.icon)))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["index", "onClick"]))
              ]))
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["default-active", "collapse"]))
}