import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Top = _resolveComponent("Top")!
  const _component_Left = _resolveComponent("Left")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Top),
    _createVNode(_component_Left),
    _createElementVNode("div", {
      class: _normalizeClass(["main-content", { active: _ctx.isCollapse }])
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ], 64))
}